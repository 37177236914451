// Dependencies
import React, { useEffect, useState, useMemo, useReducer } from "react";
import { useGetTheme, useQuery } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { httpCallables } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";
import { useIntl } from "react-intl";
import {
  generateCourseStudentsList,
  usersReducer,
  fetchTaskData,
  handleApiResponse,
  processTaskResponse
} from "./utils";
import { isBefore } from "date-fns";
import useUserClaim, { CLAIMS_ROLES } from "../../../hooks/useUserClaim";

// Redux
import { addCourse, selectCourse } from "../../../redux/coursesSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import CourseOverview from "./CourseOverview";
import SubmissionsReport from "./submissions/SubmissionsReport";
import TimeOnAssignmentReport from "./assignments/TimeOnAssignmentReport";
import StudentEngagementReport from "./engagement/StudentEngagementReport";
import CourseGradesReport from "./grades/CourseGradesReport";
import ScrollBox from "../ScrollBox";
import CustomSkeleton from "../CustomSkeleton";
// Material-UI
import { Box, Chip, Typography } from "@mui/material";
import { CHART_NAMES } from "../../admin/reports/consts";

const CourseExternalReport = () => {
  // Hooks
  const { course_id } = useQuery();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useGetTheme();
  // Redux
  const course = useSelector((state) => selectCourse(state, Number(course_id)));
  const isAdmin = useUserClaim(CLAIMS_ROLES.ADMIN);

  // Ephemeral state
  const [users, dispatchUsers] = useReducer(usersReducer, {});

  const [submissions, setSubmissions] = useState([{}]);
  const [rawSubmissions, setRawSubmissions] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [timeSpentOnAssignmentData, setTimeSpentOnAssignmentData] = useState(
    []
  );
  const [taskChartData, setTaskChartsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [taskIds, setTaskIds] = useState([]);
  const [maxPagePerTextData, setMaxPagePerTextData] = useState([]);
  // Behavior

  const [activeUsers, setActiveUsers] = useState([]);

  const courseStudentsList = useMemo(() => {
    return generateCourseStudentsList(users);
  }, [users]);

  const courseActiveStudentsList = useMemo(() => {
    return courseStudentsList.filter((u) =>
      activeUsers.includes(u.course_user)
    );
  }, [courseStudentsList, activeUsers]);

  const courseStart = course.course_start;
  const courseEnd = course.course_end;

  // Get Course task data
  useEffect(() => {
    if (Object.keys(course).length === 0 && isAdmin) {
      httpCallables
        .adminFunctions({ func_name: "getCourseById", courseId: course_id })
        .then((response) => {
          const { ...course } = response.data;
          dispatch(addCourse(course));
        });
    }
  }, [course, isAdmin]);

  const isFirstAssignmentPassed = useMemo(() => {
    return rawSubmissions.some((s) => isBefore(s.due_date, new Date()));
  }, [rawSubmissions]);

  useEffect(() => {
    if (Object.keys(course).length > 0) {
      const taskPromise = fetchTaskData(course_id, courseStart, courseEnd);
      Promise.all([taskPromise])
        .then(([taskResponse]) => {
          handleApiResponse(
            taskResponse,
            (payload) => {
              processTaskResponse(payload, {
                setSubmissions,
                setRawSubmissions,
                setCourseAssignments,
                setTaskChartsData,
                setTimeSpentOnAssignmentData,
                setTaskIds,
                dispatchUsers,
                setActiveUsers
              });
            },
            (error) => {
              console.error("API response error:", error);
              captureException(error, `Failed to get task Stats`);
            }
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [courseStart, courseEnd, course_id, course]);

  // Set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: course.name,
      url: `/tasks?course_id=${course_id}`
    });
    parts.push({
      url: `/reports/courseExternal?course_id=${course_id}`,
      text: intl.formatMessage({
        id: "internalReports.internalReport",
        defaultMessage: "Course report"
      }),
      course: course
    });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course_id, course.name, course, dispatch, intl]);

  useEffect(() => {
    if (submissions && users && courseAssignments && taskChartData) {
      setIsLoading(false);
    }
  }, [submissions, users, courseAssignments, taskChartData]);

  useEffect(() => {
    if (!taskIds.length) return;
    else {
      httpCallables
        .getMaxPagePerText({
          course_id: Number(course_id),
          task_ids: taskIds
        })
        .then(({ data }) => {
          if (data.success) {
            const response = JSON.parse(data.payload);

            setMaxPagePerTextData(response.payload);
          }
        });
    }
  }, [taskIds, course_id]);

  return (
    <ScrollBox>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingInlineEnd: "16px",
          paddingBlockEnd: "80px",
          backgroundColor: theme.palette.background.default
        }}>
        <Box
          sx={{
            width: "950px",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start"
          }}>
          <Typography variant="h6" sx={{ marginBlockStart: "40px" }}>
            {course.name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBlockEnd: "16px", marginBlockStart: "32px" }}>
            Course overview
          </Typography>
          <Box sx={{ width: "100%" }}>
            <CourseOverview
              activeUsersNum={activeUsers.length}
              submissionRate={taskChartData ? submissions.submissionRate : 0}
              totalAssignments={
                taskChartData ? taskChartData.PUBLISHED_TASKS.data.length : 0
              }
              timeSpentOnAssignmentData={timeSpentOnAssignmentData}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBlockStart: "32px"
            }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Course submissions
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"200px"} />
            ) : (
              <SubmissionsReport
                submissions={submissions}
                courseStudents={courseActiveStudentsList}
                courseAssignments={courseAssignments}
                isFirstAssignmentPassed={isFirstAssignmentPassed}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBlockStart: "32px"
            }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Time allocation
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"200px"} />
            ) : (
              <>
                <TimeOnAssignmentReport
                  data={timeSpentOnAssignmentData}
                  courseAssignments={courseAssignments}
                  courseStudents={courseActiveStudentsList}
                  tooltip={`analytics.tooltips.courseActivityReport.${CHART_NAMES.AVERAGE_TIME_SPENT}`}
                />
              </>
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Grades
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <CourseGradesReport
                data={rawSubmissions}
                courseAssignments={courseAssignments}
                courseStudents={courseActiveStudentsList}
              />
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBlockEnd: "16px"
              }}>
              <Typography variant="subtitle1" sx={{ marginRight: "8px" }}>
                Engagement
              </Typography>
              <Chip
                variant="outlined"
                label={`${courseStudentsList.length} enrolled students`}
                sx={{ height: "24px" }}
              />
            </Box>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <Box
                sx={{
                  padding: "24px",
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.border.secondary}`,
                  borderRadius: "5px"
                }}>
                <StudentEngagementReport
                  courseStudents={courseStudentsList}
                  users={users}
                  dispatchUsers={dispatchUsers}
                  start={courseStart}
                  end={courseEnd}
                  timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                  userBasedSubmissions={submissions.userBased}
                  maxPagePerTextData={maxPagePerTextData}
                  activeUsers={activeUsers}
                  isFirstAssignmentPassed={isFirstAssignmentPassed}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ScrollBox>
  );
};

export default CourseExternalReport;
