import React, { useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import InsertChartIcon from "@mui/icons-material/InsertChart";

import { TABLE_VIEW } from "../consts";
import {
  getSessionAveragesPerTaskAndStudent,
  SESSION_TYPES,
  SESSION_TIMES
} from "../utils";
import { CustomIconButton } from "../CustomToggleButton";
import PieChart from "../pieChart/PieChart";
import { RichTooltipPopover } from "../../RichTooltipPopover";
import SubmissionStackedGraph from "../stackedGraph/SubmissionStackedGraph";
import { useGetTheme } from "../../../../hooks";

const TimeOnAssignmentReport = ({ data, courseAssignments, tooltip }) => {
  const theme = useGetTheme();
  const [timeView, setTimeView] = useState(TABLE_VIEW.COURSE_VIEW);
  const isCourseView = timeView === TABLE_VIEW.COURSE_VIEW;
  // Process assignment data

  const relevantAssignments = useMemo(() => {
    const flattened = new Set(
      Object.values(data)
        .flat()
        .map((a) => a.task_id)
    );
    return courseAssignments.filter((a) => flattened.has(a.id));
  }, [data, courseAssignments]);

  // Filter relevant assignments

  const [transformedData, taskData] = useMemo(() => {
    const relevantAssignmentIds = courseAssignments.map((a) => a.id);
    const relevantData = SESSION_TIMES.reduce(
      (acc, sessionType) => ({
        ...acc,
        [sessionType]: data[sessionType].filter((a) => {
          return relevantAssignmentIds.includes(a.task_id);
        })
      }),
      {}
    );
    let [localData, localTaskData] =
      getSessionAveragesPerTaskAndStudent(relevantData);
    return [localData, localTaskData];
  }, [courseAssignments, data]);

  // Calculate course view data
  const courseViewTimeSpentOnAssignment = useMemo(() => {
    const retVal = SESSION_TYPES.reduce((acc, sessionType) => {
      const total = transformedData[sessionType]?.total;

      if (total > 0) {
        return {
          ...acc,
          [sessionType]: {
            total
          }
        };
      }

      return acc;
    }, {});
    return retVal;
  }, [transformedData]);

  const taskBasedTimeSpentOnAssignment = useMemo(() => {
    const result = {};

    // Iterate through each top-level key (e.g., "2392", "2407")
    for (const taskId in taskData) {
      result[taskId] = {};
      let taskUsers = new Set();
      for (const sessionType in taskData[taskId]) {
        taskUsers = new Set([
          ...taskUsers,
          ...taskData[taskId][sessionType].userIds
        ]);
      }

      // Iterate through each session type (e.g., "REVIEW_SESSION", "HIGHLIGHT_SESSION")
      for (const sessionType in taskData[taskId]) {
        const session = taskData[taskId][sessionType];
        const total = session.total;
        // Calculate average and assign it
        result[taskId][sessionType] = total / taskUsers.size;
      }
    }

    return result;
  }, [taskData]);

  // Render helper components
  const renderHeader = () => (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "60px",
        flexFlow: "row nowrap",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.border.secondary}`
      }}>
      <Typography variant="subtitle1">
        Average time spent on assignments step
      </Typography>
      <RichTooltipPopover
        name="Average time spent on assignments"
        body={tooltip}
      />
    </Box>
  );

  const renderViewToggle = () => (
    <Box sx={{ paddingTop: "16px" }}>
      <CustomIconButton
        icon={PieChartOutlineIcon}
        label="Course view"
        isActive={isCourseView}
        onClick={() => setTimeView(TABLE_VIEW.COURSE_VIEW)}
      />
      <CustomIconButton
        icon={InsertChartIcon}
        label="Assignment view"
        isActive={!isCourseView}
        onClick={() => setTimeView(TABLE_VIEW.ASSIGNMENT_VIEW)}
      />
    </Box>
  );

  const renderChart = () => (
    <Box sx={{ height: "80%" }}>
      {isCourseView ? (
        <PieChart
          type="timeSpentOnAssignment"
          data={courseViewTimeSpentOnAssignment}
          totalSubmissions={[0, 1, 2, 3, 4]}
          courseAssignments={relevantAssignments}
        />
      ) : (
        <SubmissionStackedGraph
          type="timeSpentOnAssignment"
          data={taskBasedTimeSpentOnAssignment}
          courseAssignments={courseAssignments}
        />
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        height: isCourseView ? "400px" : "500px",
        borderRadius: "5px"
      }}>
      {renderHeader()}
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          width: "inherit",
          height: "100%"
        }}>
        {renderViewToggle()}
        <Box>{renderChart()}</Box>
      </Box>
    </Box>
  );
};

export default TimeOnAssignmentReport;
