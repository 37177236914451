import React, { useState, useEffect } from "react";
import { TABLE_VIEW } from "../consts";
import { removeFutureAssignments, unifyIncompleteToComplete } from "../utils";
import { CustomIconButton } from "../CustomToggleButton";
import PieChart from "../pieChart/PieChart";
import SubmissionStackedGraph from "../stackedGraph/SubmissionStackedGraph";
import { Box, Typography, IconButton } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useFirestoreConnect } from "../../../../hooks/useFirestoreConnect";
import { useFirestore } from "reactfire";
import { doc, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { FEATURES } from "../../../../consts";
import useGetTheme from "../../../../hooks/useGetTheme";

const ToggleButton = ({ condition, handleClick, label }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <IconButton
      aria-label={`${label}: ${condition === true ? "on" : "off"}`}
      onClick={handleClick}
      size="large">
      {condition ? (
        <ToggleOnIcon fontSize="large" color="primary" />
      ) : (
        <ToggleOffIcon fontSize="large" />
      )}
    </IconButton>
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const SubmissionsReport = ({
  submissions,
  courseAssignments,
  courseStudents,
  isFirstAssignmentPassed
}) => {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  const theme = useGetTheme();

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.ANALYTICS }],
      storeAs: "commentsCustomConfig"
    },
    {
      collection: "userProfiles",
      doc: "defaultConfig",
      storeAs: "defaultConfig"
    }
  ]);

  const customConfig = useSelector(
    (state) => state.firestore.data.commentsCustomConfig
  );
  const defaultConfig = useSelector(
    (state) => state.firestore.data.defaultConfig
  );

  const [includeFutureAssignments, setIncludeFutureAssignments] =
    useState(false);
  const [includeIncompleteAssignments, setIncludeIncompleteAssignments] =
    useState(false);
  const [View, setView] = useState(TABLE_VIEW.COURSE_VIEW);
  const [selectedSubmissions, setSelectedSubmissions] = useState(submissions);
  const [totalSelectedSubmissions, setTotalSelectedSubmissions] = useState(0);

  const courseView = View === TABLE_VIEW.COURSE_VIEW;

  useEffect(() => {
    if (customConfig) {
      setIncludeFutureAssignments(
        customConfig.includeFutureAssignments ?? false
      );
      setIncludeIncompleteAssignments(
        customConfig.includeIncompleteAssignments ?? false
      );
    } else if (defaultConfig) {
      setIncludeFutureAssignments(
        defaultConfig.includeFutureAssignments ?? false
      );
      setIncludeIncompleteAssignments(
        defaultConfig.includeIncompleteAssignments ?? false
      );
    }
    if (isFirstAssignmentPassed) {
      setIncludeFutureAssignments(true);
    }
  }, [defaultConfig]);

  useEffect(() => {
    let updatedSubmissions = submissions;

    if (!includeFutureAssignments) {
      const { cleanedData } = removeFutureAssignments(
        updatedSubmissions,
        isFirstAssignmentPassed
      );
      updatedSubmissions = cleanedData;
    }
    if (!includeIncompleteAssignments) {
      updatedSubmissions = unifyIncompleteToComplete(updatedSubmissions);
    }

    setSelectedSubmissions(updatedSubmissions);
    const totalSubmissions = Object.values(
      updatedSubmissions?.statusBased || {}
    ).reduce((acc, entry) => {
      return (
        acc +
        (entry.total || 0) +
        (entry.incomplete || 0) +
        (entry.complete || 0)
      );
    }, 0);

    setTotalSelectedSubmissions(totalSubmissions);
  }, [includeFutureAssignments, includeIncompleteAssignments, submissions]);

  const handleToggle = (setter, value, key) => async () => {
    const newValue = !value;
    setter(newValue);
    try {
      const customConfigRef = doc(
        firestore,
        `userProfiles/customConfig/${userId}/${FEATURES.ANALYTICS}`
      );
      await setDoc(customConfigRef, { [key]: newValue }, { merge: true });
    } catch (error) {
      console.error("Firestore Error:", error.message);
    }
  };

  const handleSubmissionsViewChange = (view) => setView(view);
  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Total submissions: {totalSelectedSubmissions}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButton
            condition={includeIncompleteAssignments}
            handleClick={handleToggle(
              setIncludeIncompleteAssignments,
              includeIncompleteAssignments,
              "includeIncompleteAssignments"
            )}
            label="Indicate incomplete assignments"
          />
          <ToggleButton
            condition={includeFutureAssignments}
            handleClick={handleToggle(
              setIncludeFutureAssignments,
              includeFutureAssignments,
              "includeFutureAssignments"
            )}
            label="Include future assignments"
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ paddingTop: "16px" }}>
          <CustomIconButton
            icon={PieChartOutlineIcon}
            label="Course view"
            isActive={courseView}
            onClick={() => handleSubmissionsViewChange(TABLE_VIEW.COURSE_VIEW)}
          />
          <CustomIconButton
            icon={InsertChartIcon}
            label="Assignment view"
            isActive={!courseView}
            onClick={() =>
              handleSubmissionsViewChange(TABLE_VIEW.ASSIGNMENT_VIEW)
            }
          />
        </Box>
        <Box sx={{ height: "80%" }}>
          {courseView ? (
            <PieChart
              type="submissions"
              data={selectedSubmissions}
              totalSubmissions={totalSelectedSubmissions}
              includeFutureAssignments={includeFutureAssignments}
            />
          ) : (
            <SubmissionStackedGraph
              type="submissions"
              data={selectedSubmissions}
              totalSubmissions={totalSelectedSubmissions}
              includeFutureAssignments={includeFutureAssignments}
              courseAssignments={courseAssignments}
              courseStudents={courseStudents}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SubmissionsReport;
